.pageRegister {
    display: flex;
    flex-direction: column;

    .content {
        flex-direction: column;
        // padding-bottom: 2.5rem;
    }

    .form {
        display: flex;
        flex-direction: column;
    }

    .side-text {
        .arrow-back {
            cursor: pointer;
            color: #000;
            svg {
                margin: 1.5rem 0;
            }
        }

        .subtitle-question {
            font-family: 'Bradesco Sans';
            margin-top: 1.5rem;
            color: #767676;
        }

        .link {
            padding-top: 0.5rem !important;
            text-decoration: none !important;
        }
    }

    @media (min-width: 1056px) {
        .input-form,
        .password-container {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }

        .input-form {
            margin: 1.5rem 0;
            gap: 1.5rem;

            .password-container {
                grid-column-start: 1;
                grid-column-end: 3;
                gap: 1rem 1.5rem;

                .password-validation {
                    grid-column-end: 3;
                    grid-column-start: 1;

                    white-space: nowrap;

                    display: grid;
                    grid-template-columns: 15rem auto;

                    div:nth-child(2) {
                        margin-left: -3rem;
                    }
                }
            }
        }
    }

    .divider {
        height: 2px;
        background-color: #D9DCDD;
        margin-top: 2.5rem;
    }

    .btn-confirm {
        align-self: center;
        margin: 1.5rem 0;
    }

    .checkbox-wrapper {
        padding-top: 0.5rem;
        justify-content: center;
    }

    .bx--checkbox-label-text {
        font-size: 16px;
    }


    @media (max-width: 671px) {
        .content {
            padding: 0 1rem;
            place-content: center;
        }

        .btn-confirm {
            margin: 2rem 0;
        }

        .side-text {
            margin-right: 14%;
        }
    }

    @media (min-width: 672px) and (max-width: 1055px) {
        .content {
            padding: 1.5rem 0;
        }

        .btn-confirm {
            margin: 2.75rem 0;
        }
    }

    @media (max-width: 1055px) {
        .input-form {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            margin: 1.5rem 0;

            .password-container {
                display: grid;
                gap: 1rem;

                .input-confirm-password {
                    grid-row-start: 3;
                    margin-top: 0.25rem;
                }
            }
        }

        .divider {
            display: none;
        }
    }
}

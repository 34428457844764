.pageResetPassword {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    max-width: 100%;
    margin-bottom: 450px;

    .row-content {
        margin-top: 7.5rem;
    }

    .content-header {
        margin-bottom: 1rem;
    }

    .btn-container {
        display: flex;
        justify-content: center;
        margin-top: 2.5rem;
    }

    @media (max-width: 671px) {
        .row-content {
            margin: 2rem 1rem;
        }

        .btn-container {
            margin-top: 17rem;
        }
    }
}

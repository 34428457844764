.pageError {
    .bx--row {
        margin-top: 8rem;
        justify-content: center;
        margin-bottom: 450px;

        @media (max-width: 671px) {
            margin-bottom: 350px;
        }
    }
}

.btn-link {
    font-family: 'Bradesco Sans';
    color: #0c881e !important;
    font-size: 14px !important;
    font-weight: bold !important;
    background: none !important;
    border: none !important;
    text-decoration: underline !important;
    cursor: pointer !important;
    padding: 0 !important;
    text-align: center !important;
    justify-content: center !important;

    &:hover {
        color: #075512 !important;
    }
}

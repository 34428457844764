.toggleLogin {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 1rem;

    .bx--toggle-input:checked + .bx--toggle-input__label > .bx--toggle__switch::before {
        background-color: #0c881e;
    }

    .description {
        font-family: 'Bradesco Sans';
        font-size: 14px;
        margin: 1rem 0 0;
        cursor: pointer;
    }
}

.bx--form-item {
    flex: none !important;
}

.bx--modal-container {
    width: 600px;
    height: 500px;
    border-radius: 4px;
}

.bx--modal-close {
    display: none;
}

.bx--modal-close:focus {
    border: none;
}

.bx--modal-header {
    display: none;
}

.bx--modal-content {
    padding: 2rem 1rem 0 1rem !important;
    margin-bottom: 4rem;
    margin-top: 2rem;

    p {
        font-family: 'Bradesco Sans';
        padding-right: 0 !important;
        font-size: 14px !important;
        color: #47484c;
        white-space: pre-wrap;
    }
}

.bx--btn-set .bx--btn {
    width: 16rem !important;
}

.bx--modal-footer {
    gap: 1rem;
    display: flex;
    justify-content: center !important;
    align-items: center;
    margin: 0 auto;
    padding-bottom: 4rem;
    flex-direction: row-reverse;
}

.bx--modal-footer .bx--btn {
    font-family: 'Bradesco Sans';
    justify-content: center;
    padding: 1rem !important;
    height: 48px !important;
    border-radius: 100px;
    font-size: 14px;
    font-weight: bold;
}

.button-modal .bx--btn--primary,
.button-modal .bx--btn--primary:hover {
    background-color: transparent !important;
    border: 2px solid #0c881e !important;
    color: #0c881e;
}

.button-modal .bx--btn--secondary,
.button-modal .bx--btn--secondary:hover {
    background-color: #0c881e !important;
}

a {
    font-family: 'Bradesco Sans';
    font-weight: 600;
    color: #0c881e;
    // text-decoration: underline;
}

@media (max-width: 671px) {
    .bx--modal-container {
        margin: 2rem 1rem;
        width: 90%;
        height: 90%;
        overflow-x: hidden;
    }

    .bx--modal-content {
        padding: 1.5rem 1rem 0 1rem !important;
        overflow-x: hidden;
    }

    .bx--modal-footer {
        gap: 1rem;
        display: flex;
        justify-content: center !important;
        align-items: center;
        margin: 0 auto;
        padding-bottom: 4rem;
        flex-direction: column;
    }
}

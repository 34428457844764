.row-content {
    margin-top: 7.5rem;
    flex-direction: column;

    .content-header {
        margin-bottom: 1rem;
    }

    .inputPassword {
        margin-bottom: 1.5rem;
    }

    .btn-container {
        display: flex;
        justify-content: center;
        margin-top: 2.5rem;
    }

    @media (max-width: 671px) {
        margin: 2rem 0;

        .btn-with-margin {
            margin-top: 13rem;
        }
    }

    .password-validation {
        margin-bottom: 1.5rem;
    }
}

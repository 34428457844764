.pageUserAuthentication {
    padding-left: 1rem;
    padding-right: 1rem;

    .row-content {
        margin-top: 4rem;
        flex-direction: column;
        align-items: flex-start;
        align-self: flex-start;
        justify-content: flex-start;
        align-self: flex-start;
        align-content: flex-start;
    }

    .radio-btn {
        margin-top: 1.5rem;

        span {
            font-family: 'Bradesco Sans';
            font-style: normal;
            font-weight: 400;
        }
    }

    .row-button {
        display: flex;
        justify-content: center;
        margin-top: 15rem;
        margin-bottom: 5rem;
    }

    @media (max-width: 671px) {
        .column-container {
            padding-left: 0;
            padding-right: 0;
        }

        .row-content {
            margin-top: 1rem;
            padding: 0;
        }
    }
}



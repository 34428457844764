.pageResetPasswordConfirm {
    display: flex;
    justify-content: center;
    padding: 0;
    margin-bottom: 350px;

    @media (max-width: 671px) {
        margin: 0 1rem;
    }
}

.content-label {
    position: relative;
}

.label {
    font-family: 'Bradesco Sans';
    color: #000000;
    font-size: 14px;
}

.input {
    height: 40px;
    width: 100%;
    margin-top: 8px;
    padding: 0 1rem;
    box-sizing: border-box;
    outline: none;
    border: 2px solid #a7a8ac;
    border-radius: 8px;
    font-size: 14px;
    font-family: Bradesco Sans;

    &::placeholder {
        font-family: 'Bradesco Sans';
        color: #a7a8ac;
    }

    &.inputError {
        border: 2px solid #ea0000;
    }

    //remove icon show password nativo do Edge
    &::-ms-reveal,
    &::-ms-clear {
        display: none;
    }
}

.input:focus {
    border: 2px solid #0670bf;
}

.messageError {
    padding-top: 8px;
    color: #ea0000;
    font-size: 14px;
}

.icon {
    position: absolute;
    top: 42%;
    right: 1rem;
    color: #a7a8ac;

    svg {
        width: 1rem;
        height: 1rem;
    }
}

.error-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;

    .title {
        white-space: pre-line;
        text-align: center;
    }

    .icon-error {
        background: url('../assets/Error.svg') no-repeat;
        width: 40px;
        height: 40px;
    }

    .icon-success{
        background: url('../assets/Success.svg') no-repeat;
        width: 40px;
        height: 40px;
    }

    h1 {
        font-family: 'Bradesco Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        color: #000000;

        @media (max-width: 671px) {
            font-size: 18px;
        }
    }

    .btn-link {
        font-family: 'Bradesco Sans';
        font-style: normal;
        text-decoration: none !important;
        font-weight: 600;
    }
}

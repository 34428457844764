.pageUserAuthenticationConfirm {

    .bx--grid {
        padding-right: 0;
        padding-left: 0;
    }

    .arrow-back {
        cursor: pointer;
        color: #000;
        svg {
            margin: 1.5rem 0;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 0;
        margin-left: 0;
    }
 
    .margin-top {
        margin-top: 4.5rem;
    }

    .content-header {
        display: grid;
        justify-items: center;
        width: 450px;

        p {
            text-align: center;
        }
    }

    .input-container {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .btn-resend {
        text-decoration: none !important;
    }

    .ipt-code {
        margin-top: 3rem;
        margin-bottom: 1rem;
        gap: 5px;

        input {
            font-size: 1rem;
            font-weight: bold;
        }
    }

    .timer {
        font-family: 'Bradesco Sans';
        font-style: normal;
        font-weight: 400;
        color: #0670bf;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .msg-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        margin-top: 3rem;
    }

    .msg-resend {
        font-family: 'Bradesco Sans';
        font-style: normal;
        font-weight: 400;
        color: #6d6e71;
        font-size: 0.875rem;
    }

    .msg-error {
        color: #d23e3e;
        font-size: 0.875rem;
        margin-top: 2rem;
    }

    .alert-banner {
        margin: 2.5rem 1rem 0 1rem;
        max-width: 570px;
        line-height: 1.5;
    }

    .btn-confirm {
        margin: 1.5rem 0;
    }

    .divider {
        height: 2px;
        background-color: #D9DCDD;
        width: 100%;
        margin-top: 2rem;
    }

    @media (max-width: 672px) {

        .arrow-back {
            svg {
                margin: 1.5rem 0 1.5rem 1rem;
            }
        }
        .content {
            margin: 0 auto;
        }

        .content-header {
            margin-top: 2rem;
            justify-items: flex-start;
            width: 300px;

            p {
                text-align: left;
            }
        }

        .msg-container {
            display: flex;
            align-items: flex-start;
            gap: 8px;
        }

        .msg-resend {
            margin-top: 8px;
            margin-bottom: 1rem;
        }
    }

    @media (max-width: 1055px) {
        .divider {
            display: none;
        }
    }
}

.checkbox-wrapper {
    .bx--checkbox-label::before {
        border-width: 1px;
        border-color: #0C881E;
        margin-right: 0.5rem;
    }

    .bx--checkbox:checked + .bx--checkbox-label::before {
        border-color: #0C881E;
        background-color: #0C881E;
    }

    .bx--checkbox-label-text {
        font-family: 'Bradesco Sans';
        padding-left: 0.5rem;
    }
}

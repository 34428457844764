
  input {
    border: 1px solid #e3e3e3;
    background: #ffffff 0% 0% no-repeat padding-box;
    height: 40px;
    min-width: 30px;
    border-radius: 8px;
  }

  .focusInput {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #0670BF;
    outline: none;
  }

  .inputError {
    border: 2px solid #E1173E;
    cursor: pointer;
  }


  input::placeholder {
    color: transparent;
  }
.content-header {
    h1 {
        font-family: 'Bradesco Sans';
        color: #0670bf;
        font-size: 22px;
        font-weight: 600;
    }

    p {
        font-family: 'Bradesco Sans';
        padding-top: 1rem;
        font-size: 16px;
        font-weight: normal;
    }
}

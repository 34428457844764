.pageLogin {
    body {
        font-family: 'BradescoSans !important';
    }

    .bx--row {
        margin: 0;
        padding: 0;
        flex-wrap: initial;
    }

    .form {
        display: flex;
        justify-content: center;
        margin: 7rem 0;
        @media (max-width: 671px) {
            margin: 2rem 0;
        }
    }

    .login-container {
        flex-direction: column;
        width: 400px;
    }

    .title-container {
        justify-content: flex-start;
        padding-bottom: 1.5rem;

        .title {
            font-family: 'Bradesco Sans';
            color: #0670bf;
            font-size: 22px;
            font-weight: bold;
        }
    }

    .messageError {
        padding-bottom: 0.5rem;
        color: #ea0000;
        font-size: 14px;
    }

    .row-inputs {
        display: grid;
        gap: 1.5rem;
    }

    .bottom-container {
        flex-direction: column;
        align-items: center;
        margin-top: 2rem !important;
        .btn-login {
            @media (max-width: 671px) {
                width: 100%;
            }
        }

        .btn-forgotPassword {
            text-decoration: underline;
            margin: 1.5rem 0;
        }

        .row-register {
            gap: 1rem;
            margin-bottom: 2rem;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            span {
                font-family: 'Bradesco Sans';
                font-style: normal;
                font-weight: 400;
            }
        }

        .connect-social-media {
            font-family: 'Bradesco Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
        }

        .btn-register {
            text-decoration: underline;
            width: 100px;
        }
    }
    .icons {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: 44px;
        margin: 0 auto;
        div {
            background-size: cover;
            width: 25px;
            height: 25px;
            margin-right: 1rem;
            cursor: pointer;
        }

        .google {
            background-image: url(./icone_google.svg);
        }

        .facebook {
            background-image: url(./icone_facebook.svg);
        }

        .apple {
            background-image: url(./icone_apple.svg);
        }
    }
}

.pageRecoverPassword {
    align-content: center;
    flex-direction: column;
    margin-bottom: 550px;

    .recover-header {
        margin-top: 7.5rem;
        margin-bottom: 1.5rem;
    }

    .expired-message {
        display: flex;
        align-items: center;
        gap: 8px;

        svg {
            width: 16px;
            height: 16px;
            color: #0670bf;
        }

        h1 {
            font-size: 14px;
            color: #6d6e71;
        }
    }
    @media (max-width: 671px) {
        margin: 0 1rem 450px 1rem !important;

        .recover-header {
            margin-top: 2rem;
        }
    }
}

.pageUpdateProfile {
    display: flex;
    flex-direction: column;

    .content {
        align-self: center;
        flex-direction: column;
        padding: 4.5rem 0;
    }

    .form {
        display: flex;
        flex-direction: column;
    }

    .side-text {
        max-width: 430px;

        .subtitle-question {
            font-family: 'Bradesco Sans';
            font-style: normal;
            font-weight: 400;
            margin-top: 1.5rem;
            color: #767676;
        }

        .link {
            padding-top: 0.5rem !important;
            text-decoration: none !important;
        }
    }

    .input-form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1.5rem;
        margin: 1.5rem 0;
    }

    .btn-confirm {
        align-self: center;
        margin: 5rem 0;
    }

    .checkbox-wrapper {
        padding-top: 0.5rem;
        justify-content: center;
    }

    .bx--checkbox-label-text {
        font-size: 16px;
    }

    @media (max-width: 671px) {
        .content {
            padding: 1.5rem 1rem;
            .input-form {
                display: flex;
                flex-direction: column;
            }
        }

        .btn-confirm {
            margin: 2rem 0;
        }
    }

    @media (min-width: 672px) and (max-width: 1055px) {
        .content {
            padding: 1.5rem 0;
            .input-form {
                display: flex;
                flex-direction: column;
            }
        }

        .btn-confirm {
            margin: 2.75rem 0;
        }
    }

   
}

.bx--radio-button,
.bx--radio-button:checked {
    & + .bx--radio-button__label {
        .bx--radio-button__appearance {
            border-color: #0c881e;

            &::before {
                background-color: #0c881e;
            }
        }
    }
}
